.faq-section{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.faq-container {
    width: 60%;
    max-width: 100%;
    margin: 0 auto;
    font-family: 'Poppins', sans-serif;
    margin: 100px;
    display: flex;
    flex-direction: column
  }
  
  .faq {
    margin-top: 00px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }
  
  .faq-item {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
    width: 100%;
  }
  
  .faq-question {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #383141;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 24px */
    text-align: left;
  }
  
  .faq-question.active {
    color: #28a745; /* green color for active */
  }
  
  .faq-answer {
    margin-top: 10px;
    color: #706183;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    text-align: left;
  }
  
  .arrow {
    font-size: 16px;
    color: #28a745;
  }
  