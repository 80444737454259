.container-div {
    max-width: 100%;
    margin: 0px ;
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  h2 {
    color: #333;
  }
  .section1-main{
    background-color: #DEF8E1;
    padding: 80px;
    padding-left: 0px;
    padding-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 20px ;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 100px;
  }
  .section1 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 30px;
  }
  .cartoon-img-div{
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    max-width: 35%;
  }
  .bl-calc{
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .bl-calc-h1{
    color: #3D3D3D;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 60px */
    text-align: center;
    margin-bottom: 50px;
  }

  .slider {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    /*width: 884.079px;*/
    padding: 30px 60px;
    align-items: flex-start;
    border-radius: 15px;
    background-color: white;
  }
  .slider-sub-div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  .slider-label{
    color:  #3F3F3F;

    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 31.612px; /* 125% */
  }
  .select-lp-type{
    color: var(--www-creditenable-com-mine-shaft, var(--color-grey-25, #3F3F3F));
    text-align: center;
    font-family: Poppins;
    font-size: 28.451px;
    font-style: normal;
    font-weight: 600;
    line-height: 53.741px; /* 188.889% */
  }

.label-loan{
  color:#3F3F3F;
  font-family: 'Poppins';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 31.612px; /* 125% */
}
  .section2 {
    background-color: #f0f4f7;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    
    background-color: #0F5936;;
    padding: 80px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 20px ;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 30px;
    margin-top: 100px;
    width: 100%;
    color: white;
  }
  
  label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
    align-content: center;
  }
  
  input[type="range"] {
    width: 100%;
  }

  input[type="radio"]:checked {
    accent-color: green; /* Changes the color of the selected radio button */
  }
  
  .loan-type {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  
  .loan-type div {
    margin: 10px 0;
  }
  .lp-type-radio-input-div{
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .next-btn, .submit-btn {
    background-color: #28a745;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px 20px;
  }

  .prod-finder-option-div{
    background-color: white;
    padding: 30px;
    border-radius: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap:   30px;
  }
  .pf-svg-div{
    display: flex;
    width: 65px;
    height: 52px;
    padding: 11.993px 18.478px 12px 18.516px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 10px;
    background: #E4F7EE;
  }
  .pf-text-div{
    display: flex;
    flex-direction: column;
  }
  .pf-option-h1{
    color: #0F5936;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */
    margin-bottom: 30px;
  }
  select {
    width: 100%;
    padding: 5px;
    border: 0px;
    background-color: #EDEDED;
    border-radius: 10px;
    width: 100%;

    color: #4C4C4C;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
  }
  

  /*--------------------Slider-------------------------*/

  /* Overall slider container for spacing */
.slider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

/* Base styling for the slider */
/* Slider container */
.slider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

/* Base styling for the slider */
input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px;
  background: #E6E6E6; /* Default background for the track (after thumb) */
  border-radius: 5px;
  outline: none;
  transition: background 0.3s ease;
}

/* Customizing the thumb (dragger) */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #168D54; /* Thumb color */
  cursor: pointer;
}

/* For Firefox */
input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #168D54;
  cursor: pointer;
}

/* Styling for Firefox track */
input[type="range"]::-moz-range-track {
  background: #E6E6E6; /* Background color after thumb */
  height: 8px;
  border-radius: 5px;
}
